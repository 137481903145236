export const quotes =[
    {
        quote:"To be honest, I'm cuter than him [referring to a small white pomeranian],especially now with my bangs",
        speaker:'Park Jimin'
    },
    {
       quote: "It may feel like the world is collapsing when you don't get the results you want. But that's not true. That's not how our lives work. What's important is you get back up" ,
       speaker: 'Min Yoongi on VLive'
    },
    {
        quote:"I wish there was a better word than ‘love.’ I really, truly love you. Please know that",
        speaker:'Kim Namjoon'
    },
    {
        quote:"I live to Understand the world, but the world has never understood me",
        speaker:"Kim Namjoon"
    },
    {
       quote:"Maybe I made a mistake yesterday, but yesterday's me is still me. Today I am who I am with all of my mistakes & faults. Tomorrow, I might be a tiny bit wiser and that would still be me too.",
       speaker: "Kim Namjoon" 
    },
    {
        quote:"I don't have think",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Remember there is a person here in Korea, in the city of Seoul, who understands you.",
        speaker:"Park Jimin from Dear Class of 2020"
    },
    {
        quote:"My name is R-A-P monster not a D-A-N-C-E monster",
        speaker:"Kim Namjoon"
    },
    {
        quote:"If we could reduce your pain from 100 to 99, 98 or 97, that makes our existence worthy.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Art isn’t hard. It’s right next to us",
        speaker:"Kim Namjoon"
    },
    {
        quote:"I know her face but I don't know her name",
        speaker:"Jeon JungKook"
    },
    {
        quote:"If you think you'll crash, accelerate more you idiot",
        speaker:'Min Yoongi from Intro: Nevermind'
    },
    {
        quote:"Life is tough, and things don't always work out well, but we should be brave and go on with our lives. ",
        speaker:"Min Yoongi on VLive"
    },
    {
        quote:"Not everyone needs to have a dream. You should live your life your own way.",
        speaker:"Min Yoongi on VLive"
    },
    {
        quote:"You were born to be real, not perfect",
        speaker:"Min Yoongi from a concert Ment"
    },
    {
        quote:"You know BTS?",
        speaker:"Min Yoongi"
    },
    {
        quote:"Don't be trapped in someone else's dream.",
        speaker:"Kim Taehyung"
    },
    {
        quote:"If you can't respect, don't even open your mouth",
        speaker:"Kim Namjoon"
    },
    {
        quote:"You can't just come into someone's life, make them feel special, and then leave.",
        speaker:"Kim Taehyung"
    },
    {
        quote:"You can't just come into someone's life, make them feel special, and then leave.",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Living without passion is like being dead.",
        speaker:"Jeon JungKook"
    },
    {
        quote:"Go on your path, even if you live for a day.",
        speaker:"Park Jimin"
    },
    {
         quote:"Purple is the last color of the rainbow. Purple means I will trust and love you for a long time.",
         speaker:"Kim Taehyung"
    },
    {
        quote:"When something is delicious. It's zero calories.",
        speaker:"Kim Seokjin"
    },
    {
       quote:"If we all gather our embarrassment together, it’ll become confidence.",
       speaker:"Kim Seokjin" 
    },
    {
        quote:"When things get tough, look at the people who love you! You will get energy from them",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Without anger or sadness, you won’t be able to feel true happiness.",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"OUR ARMY",
        speaker:"Jung Hoseok on Weverse 2022"
    },
    {
        quote:"Covid get the hell out",
        speaker:"Kim Namjoon"
    },
    {
        quote:"My existence itself is A-cut quality",
        speaker:"Kim Seokjin 2020"
    },
    {
        quote:"Do you want to see my cat?",
        speaker:"Jeon Jungkook on VLive"
    },
    {
        quote:"It feels like this bundle of worries have been lifted from my mind with today marking the start of even better days to come!",
        speaker:"Jung Hoseok on Weverse"
    },
    {
        quote:"Is your iron hand ready?",
        speaker:"Kim Namjoon"
    },
    {
        quote:"You need to have a younger mindset to become younger.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"He was named after me. He is my best friend",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Even if it's a group just like us, a lot of them don't get along",
        speaker:"Jeon Jungkook In Break The Silence : DOCU-SERIES"
    },
    {
        quote:"As expected, It's indeed faster to become BTS than to succeed at ticketing for BTS",
        speaker:'Kim Seokjin'
    },
    {
        quote:"In our concert we never have no empty seats.",
        speaker:"Min Yoongi Lyrics in Mic Drop"
    },
    {
        quote:"We work extra hard too!",
        speaker:"Kim Taehyung"
    },
    {
        quote:"There was a time when ARMY gave us a gift. Using purple army bombs.",
        speaker:"Park Jimin"
    },
    {
        quote:"Everyone, BTS is strong. You have to be careful!",
        speaker:"Kim Namjoon"
    },
    {
        quote:"All I could say is that my face was fancy",
        speaker:"Kim Seokjin"
    },
    {
        quote:"RM takes care of us when we wok as a team. But privately, the six of us take care of RM.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Win, lose, I don't care because at the end of the day I still have this face so who's the real winner here?",
        speaker:"Kim Seokjin"
    },
    {
        quote:"My handsomeness is staining my life",
        speaker:"Kim Seokjin"
    },
    {
        quote:"I am worldwide handsome",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Do you know how hard it is to write on a flip phone, huh?",
        speaker:"Kim Seokjin"
    },
    {
        quote:"I wish I could come here with my girlfriend... but I don't have one",
        speaker:"Kim Seokjin"
    },
    {
        quote:"I didn't know fire could be that hot",
        speaker:"Kim Seokjin"
    },
    {
        quote:"English is not a barrier when you're as cute as me",
        speaker:"Park Jimin"
    },
    {
        quote:"I was born in Busan first",
        speaker:"Park Jimin"
    },
    {
        quote:"Don't worry I'm not afraid",
        speaker:"Park Jimin"
    },
    {
        quote:"Nice paprika",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Jimine Pabo",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Even when I'm in the bathroom, I'm thinking of out ARMY",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Sexy Porn star(saxophonist)",
        speaker:"Kim Taehyung"
    },
    {
        quote:"In my next life I want to be born as a rock",
        speaker:"Min Yoongi"
    },
    {
        quote:"Suga, genius. These 2 words are enough",
        speaker:"Min Yoongi"
    },
    {
        quote:"I hate snakeu",
        speaker:"Jung Hoseok"
    },
    {
        quote:"I'm very loser",
        speaker:"Jung Hoseok"
    },
    {
        quote:"I'm you're hope, I'm you're angel",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Dirty water in my faceu",
        speaker:"Jung Hoseok"
    },
    {
        quote:"I am not your oppa",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"My name is Jungkook..scale is international playboy",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"I know face but I don't know name, I know movie but I don't know movie name",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Ding Dong",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Waking up is the second hardest thing in the morning.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Tooonnnyyyy!",
        speaker:"Park Jimin"
    },
    {
        quote:"Jin is weak and old.",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Hannah Montana says nobody’s perfect, but here I am.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"I’ve changed my name to J-Horse now.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Justin Seagull",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Cheaters never win, but I just graduated.",
        speaker:"Min Yoongi"
    },
    {
        quote:"R-Revolution E-Evolution D-Drop in the ocean/drobbleution",
        speaker:"Kim Seokjin"
    },
    {
        quote:"P-A-S-T-A Pasta P-I-Z-Z-A pizza",
        speaker:"Kim Seokjin"
    },
    {
        quote:"I don't have friends….Ahh BTS! my friends!",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Jhoooooope",
        speaker:"Min Yoongi"
    },
    {
        quote:"Infires Man!",
        speaker:"Min Yoongi"
    },
    {
        quote:"Jjang Jjang Man Boong Boong",
        speaker:"Min Yoongi"
    },
    {
        quote:"Uh huh listen, boy. My first love story",
        speaker:"Min Yoongi"
    },
    {
        quote:"I like puppy",
        speaker:"Min Yoongi"
    },
    {
        quote: "Infires Man!",
        speaker:"Min Yoongi"
    },
    {
        quote:"Swag",
        speaker:"Min Yoongi"
    },
    {
        quote:"I’m a bad boy",
        speaker:"Min Yoongi"
    },
    {
        quote:"It’s so hot ARMY because…..of you",
        speaker:"Min Yoongi"
    },
    {
        quote:"Eat Sleep Work",
        speaker:"Min Yoongi"
    },
    {
        quote:"I own the copyrights!",
        speaker:"Min Yoongi"
    },
    {
        quote:"I’m your hope, you’re my hope, I am J-hope!",
        speaker:"Jung Hoseok"
    },
    {
        quote:"I like kimchi fried rice… and sprite.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"I like hamburger and sprite",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Sorry. We’re on an important business",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Strong Power Thank You.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Jimin… you got no jams.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Pornesian Parapio",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Haters gonna hate, player gonna play, live your life man good luck",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Why you sad? I don’t know nan molla",
        speaker:"Kim Namjoon"
    }, 
    {
        quote:"Can I have some bottle of watah! harry potah! professah! shut up Malfoy shut up!",
        speaker:"Kim Namjoon"
    },
    {
        quote:"It's a fish bread but not fish bread. Ain't no fish inside.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Excuse Me",
        speaker:"Park Jimin"
    },
    {
        quote:"I’m so Lovely, You so Lovely, We so Lovely. lovely Lovely Lovely.",
        speaker:"Park Jimin"
    },
    {
        quote:"Jimin you nice keep going",
        speaker:"Park Jimin"
    },
    {
        quote:"We have different Clothes",
        speaker:"Park Jimin"
    },
    {
        quote:"lachimolala",
        speaker:"Park Jimin"
    },
    {
        quote:"Sexy, cute, lovely.",
        speaker:"Park Jimin"
    },
    {
        quote:"I’m God",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Turn up",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Panda Express",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Color changing soup",
        speaker:"Kim Taehyung"
    },
    {
        quote:"I'm good boy",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Not Not No",
        speaker: "Kim Taehyung"
    },
    {
        quote:"Shit down",
        speaker:"Kim Taehyung"
    },
    {
        quote:"I like country side!",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Let's get it!",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Trusfrated",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Party Party yeaaaahh I say this now everytime I am out",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"I’m not drunk. It’s just that I’m buffering.",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"He must have been homeschooled. (Referring to RM after he tried to write on a blackboard with a marker)",
        speaker:"Park Jimin"
    },
    {
        quote:"He (Yoongi) looks cool, like a fatal vampire type of guy. I want to give him my blood.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"I cook my cereal",
        speaker:"Min Yoongi"
    },
    {
        quote:"You are soo mean bouncy balls",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Sexy,cute,lovely",
        speaker:"Park Jimin"
    },
    {
        quote:"Jimin J-I-M-I-N This is my name",
        speaker:"Park Jimin"
    },
    {
        quote:"I’m pig.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Can you speak more slush",
        speaker:"Kim Taehyung"
    },
    {
       quote:"I’m proud of you maan-",
       speaker:"Kim Taehyung" 
    },
    {
        quote:"Rrrrrrrrrrraaaaapp Monstaaaaaaaaa!",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"You can’t stop me I’m Beyoncé!",
        speaker:"Kim Seokjin"
    },
    {
        quote:"#1, Safety! #2, Safety! #3, Coolness!",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Give me chicken!",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"If you want to love others, I think you should love yourself first",
        speaker:"Kim Namjoon"
    },
    {
        quote:"I have a big heart full of love, so please take it all.",
        speaker:"Kim Taehyung"
    },
    {
        quote:"Those who want to look more youthful should live life with a young heart",
        speaker:"Kim Seokjin"
    },
    {
        quote:"I have come to love myself for who I am, for who I was, and for who I hope to become.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Your presence can give happiness. I hope you remember that.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Life is a sculpture that you cast as you make mistakes and learn from them.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"No sleep makes a human into another human.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"I’m like a surfer, first you just paddle and fall off the board but as time goes by you can stand up on the bigger waves.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"We are all in different parts of the world, in different environments and circumstances, but at this moment, I hope we can all give each other a warm pat on the back and say, ‘it’s okay'.",
        speaker:"Park Jimin"
    },
    {
        quote:"Even if it’s a road of thorns, we still run.",
        speaker:"Min Yoongi"
    },
    {
        quote:"They say people live to be happy. If you actually think about what happiness is, it’s nothing much. When you get to eat ramen after feeling really, really hungry, that’s happiness.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Not so perfect, but so beautiful",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Isn’t a man someone who doesn’t care about what others think? A man does whatever he wants.",
        speaker:"Jeon Jungkook"
    },
    {
        quote:" My good luck charm is my face.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"I’m always afraid of making mistakes. I think I was born with that.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Find your name, find your voice by speaking yourself.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Even when this rain stops, when clouds go away, I stand here, just the same.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Life is tough, and things don’t always work out well, but we should be brave and go on with our lives.",
        speaker:"Min Yoongi"
    },
    {
        quote:"A warm smile is the universal language of kindness.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"I believe that there’s no improvement if you have an inferiority complex and victim mentality.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Live life with no worries.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Once your heart is moved, it will develop to something better and positive.",
        speaker:"Park Jimin"
    },
    {
        quote:"Where you stand now is what defines you.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Emotions are so different in every situation and every moment, so I think to agonize every moment is what life is.",
        speaker:"Min Yoongi"
    },
    {
        quote:"Know that the pain will pass. And when it does you will be stronger.",
        speaker:"Park Jimin"
    },
    {
        quote:"Even if you’re not perfect, you’re a limited edition",
        speaker:"Kim Namjoon from Do You"
    },
    {
        quote:"You never know the value of what you have until you lose it.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Let’s not push off the things we need to do today for tomorrow.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"All our dreams come true, if we have the courage to pursue them.",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Life isn’t about being perfect; it’s about accomplishing your dreams.",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"Never give up on a dream that you’ve been chasing almost your whole life.",
        speaker:"Park Jimin"
    },
    {
        quote:"I would like to ask all of you. What is your name? What excites you and makes your heart beat?",
        speaker:"Kim Namjoon"
    },
    {
        quote:"When things get hard, stop for awhile and look back and see how far you’ve come. Don’t forget how rewarding it is.",
        speaker:"Kim Taehyung"
    },
    {
        quote:"We’re on some path that’s set since we’re born, but I still believe we can change some things. So I believe in my faith but I still don’t believe in my fate.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Even if I am a little hurt it's ok",
        speaker:"Park Jimin"
    },
    {
        quote:"go hospital plz",
        speaker:"Kim Seokjin on Weverse 2019"
    },
    {
        quote:"To live out as I am the death of me",
        speaker:"Kim Namjoon on Weverse"
    },
    {
        quote:"R U riding?",
        speaker:"Jung Hoseok on Weverse 2019"
    },
    {
        quote:"You'd better get out of the imagination, it's not good in there",
        speaker:"Kim Taehyung on Weverse 2019"
    },
    {
        quote:"Dad jokes dont work unless you are handsome",
        speaker:"Kim Seokjin on Weverse"
    },
    {
        quote:"Suga Study Method: Don't",
        speaker:"Min Yoongi on Weverse 2019"
    },
    {
        quote:"I personally don't like people",
        speaker:"Min Yoongi"
    },
    {
        quote:"It is like life is soup and I am a fork",
        speaker:"Min Yoongi"
    },
    {
        quote:"If it is fake love then it has to be sexual",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"My butt was almost destroyed",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Those who keep trying without giving up are the ones who succeed.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"You worked hard—keep trying your best.",
        speaker:"Kim Seokjin"
    },
    {
        quote:"If any of you feels lost in the face of uncertainty or the pressure of starting anew, don’t rush. Take a deep breath. You may find that any moment can be turned into opportunity.",
        speaker:"Kim Seokjin"   
    },
    {
        quote:"Life is tough and things don’t always work out well, but we should be brave and go on with our lives.",
        speaker:"Min Yoongi"
    },
    {
        quote:"Please don’t be afraid, don’t worry yourself. The end and beginning, beginning and end are connected.",
        speaker:"Min Yoongi"
    },
    {
        quote:"No one else can take responsibility for you.",
        speaker:"Min Yoongi"
    },
    {
        quote:"When things get tough, look at the people who love you! You will get energy from them.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"Remember: You are the leader of your own life, and chant it over and over again. ‘I can do it. You got it. I can do it well. I can do it better than anybody.",
        speaker:"Jung Hoseok"
    },
    {
        quote:"I want to hear your voice…no matter who you are, where you’re from, your skin color, gender identity: Speak yourself.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"We may be seeing each other through just a camera on this small screen, but I know your future will bloom to something much bigger and magnificent",
        speaker:"Kim Namjoon"
    },
    {
        quote:"My songs have made me someone who constantly observes society and wants to be a person who can have a better, positive impact on other people.",
        speaker:"Kim Namjoon"
    },
    {
        quote:"We did say that you don’t have to dream, but living a life without dreams or hope would be quite dim, wouldn’t it?",
        speaker:"Kim Namjoon"
    },
    {
        quote:"Once your heart is moved, it will develop to something better and positive.",
        speaker:"Park Jimin"
    },
    {
        quote:"Silence is golden. Don’t waste time.",
        speaker:"Park Jimin"
    },
    {
        quote:"I hope you will never give up. Remember there is a person here in Korea, in the city of Seoul, who understands you.",
        speaker:"Park Jimin"
    },
    {
        quote:"Ah![I told you] I'm really a super-antibody",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"I want to throw myself away",
        speaker:"Min Yoongi"
    },
    {
        quote:"My mom saw me on tv and told me I looked like a boiled dumpling",
        speaker:"Min Yoongi"
    },
    {
        quote:"I truly believe that water can solve all of our problems. Clear skin? drink water. Weight loss? drink water. Tired of someone? Drown them",
        speaker:"Jeon Jungkook"
    },
    {
        quote:"I haven't burped since I debuted",
        speaker:"Kim Seokjin"
    },
    {
        quote:"Salt is salty,so it'll be salty",
        speaker:"Kim Seokjin"
    },
    {
        quote:"If your heart is not beating,it means you're dead",
        speaker:"Kim Seokjin"
    },
    {
        quote:'"Daydream" is a dream you have in the day',
        speaker:"Jung Hoseok"
    },
    {
        quote:"Maybe I was a vampire in my previous life. Thats why I am so handsome. A vampire that never gets old. I guess thats what I was",
        speaker:"Kim Seokjin"
    },
    {
        quote:"I am the only 10 here, while everyone else is an 8",
        speaker:"Kim Seokjin"
    },
    {
        quote:"We don’t feel the best but i think it’s good to be honest. we can be sad today and be okay tomorrow!",
        speaker:"Kim Namjoon from VLive on April 4th 2022 after loosing at the Grammys"
    },
    {
        quote:"I believe in fate so I hope something good happens. It doesn't have to be to me but to those around me. I hope the good energy goes towards me and others.",
        speaker: "Jeon Jungkook on Vlive"
    },
    {
        quote: "Your support encouraged me and took my worries away. Thank you! I love you!",
        speaker:"Jeon Jungkook"
    },
    {
        quote: "We don't dwell in the past. We only live in the present and the future.",
        speaker:"Min Yoongi"
    },
    {
        quote: "Everything becomes a bad thing when you think negative. When you think positive, it becomes better.",
        speaker:"Kim Seokjin in Bon Voyage Season 2"
    },
    {
        quote:"I'm here to save you, I'm here to ruin you,You're the one who called me, see how it's sweet",
        speaker: "Kim Taehyung"
    },
    {
        quote: "it is true, when BTS meets ARMY, the desert becomes an ocean",
        speaker: "Jung Hoseok"
    },
    {
        quote: "All the members have a lot of energy. The way I see it, army is like our battery. ARMY that makes us move, i love you!",
        speaker: "Jung Hoseok"
    },
    {
        quote: "Just focus on positive vibes. I know it's hard but life is too short to focus on the negatives",
        speaker: "Kim Namjoon"
    },
    {
        quote:"It's the nature of life that things don't stay the same.",
        speaker: "Min Yoongi"
    }
]